/* This text will be displayed below in the header of the people page */

import { PageHeader } from '../../types';
import photo from './cover_photo.jpg';

const header: PageHeader = {
  coverPhoto: photo,
  headerStatement: `We are a team of faculty, post-docs, teachers, and students, 
  dedicated to advancing our understanding of dynamic locomotion 
  and manipulation through robotics.`,
};

export default header;
