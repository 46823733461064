import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import People from '../components/people/People';

const PeoplePage = () => (
  <Layout>
    <SEO
      title='Our Team | MIT Biomimetic Robotics Lab'
      description='People page for the MIT Biomimetic Robotics Lab'
    />
    <People />
  </Layout>
);

export default PeoplePage;
