import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

import { Profile } from '../../types';
import ProfileCardButtons from './ProfileCardButtons';
import FadeInSection from '../FadeInSection';
import { colorPalette } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '225px',
      margin: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: `3px solid ${colorPalette.mitRed}66`,
      [theme.breakpoints.down('xs')]: {
        margin: '10px 5px 10px 5px',
      },
      boxShadow: '0 1px 5px 0px rgba(38,59,94,0.3)',
      '&:hover,&:focus-within': {
        borderBottom: '3px solid ' + colorPalette.mitRed,
        boxShadow: '0 0px 15px 5px rgba(38,59,94,0.3)',
        transition: '0.25s ease-out',
      },
    },
    cardContent: {
      width: '98%',
      paddingBottom: 0,
      paddingTop: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
    },
    fullName: {
      fontSize: '18px',
    },
    role: {
      fontSize: '14px',
    },
  })
);

const ProfileCard = ({ profile }: { profile: Profile }): JSX.Element => {
  const classes = useStyles();
  const fullName = profile.first + ' ' + profile.last;
  return (
    <FadeInSection>
      <Card className={classes.root}>
        <CardMedia component='img' alt={fullName} image={profile.image} />
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' component='h3' className={classes.fullName}>
            {fullName}
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
            component='h4'
            className={classes.role}
          >
            {profile.role}
          </Typography>
        </CardContent>
        <ProfileCardButtons email={profile.email} site={profile.site} />
      </Card>
    </FadeInSection>
  );
};

export default ProfileCard;
