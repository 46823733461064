import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import header from '../../assets/people/header';
import { colorPalette, screenWidthSettings } from '../../constants';
import { PeopleProfiles, State } from '../../types';
import PageHeader from '../PageHeader';
import ScrollUpButton from '../ScrollUpButton';
import PeopleRefineResults from './PeopleRefineResults';
import PeopleSection from './PeopleSection';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'black',
      minHeight: '75vh',
      width: '100vw',
      maxWidth: screenWidthSettings.maxScreenWidth,
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
    contentContainer: {
      minHeight: '100vh',
      width: '100%',
      maxWidth: screenWidthSettings.maxScreenWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
  })
);

const People = () => {
  const classes = useStyles();
  const currentProfiles = useSelector(
    (state: State): PeopleProfiles => state.peopleReducer.displayProfiles
  );
  return (
    <Container className={classes.root} component='section'>
      <PageHeader
        title='People'
        coverPhoto={header.coverPhoto}
        headerStatement={header.headerStatement}
        dividerColor={colorPalette.mitRed}
        scrollToElementID='people-refine-results'
      />
      <Container className={classes.contentContainer}>
        <PeopleRefineResults />
        <PeopleSection
          profileArr={currentProfiles.current}
          sectionTitle='Current'
        />
        <PeopleSection
          profileArr={currentProfiles.former}
          sectionTitle='Former'
        />
      </Container>
      <ScrollUpButton arrowColor={colorPalette.mitRed} />
    </Container>
  );
};

export default People;
