import React from 'react';

import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CardActions, Button } from '@material-ui/core';

import { colorPalette } from '../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    cardActions: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 0,
      color: colorPalette.mitRed,
    },
    icon: {
      fontSize: 24,
    },
  })
);

const ProfileCardButtons = ({
  email,
  site,
}: {
  email: string;
  site: string;
}): JSX.Element => {
  const classes = useStyles();
  const personalSiteButton = (
    <Tooltip title='Personal Site' placement='bottom' arrow>
      <Button size='small' color='inherit' href={site} target='_blank'>
        <PersonIcon className={classes.icon} />
      </Button>
    </Tooltip>
  );
  const emailButton = (
    <Tooltip title='Email' placement='bottom' arrow>
      <Button size='small' color='inherit' href={'mailto:' + email}>
        <EmailIcon className={classes.icon} />
      </Button>
    </Tooltip>
  );
  const noLinksIcon = (
    <Button size='small' color='inherit' disableRipple>
      <RemoveIcon className={classes.icon} />
    </Button>
  );

  // if links are provided for both email and personal site
  if (email.length > 0 && site.length > 0) {
    return (
      <CardActions className={classes.cardActions}>
        {emailButton}
        {personalSiteButton}
      </CardActions>
    );
  } else if (email.length > 0) {
    return (
      <CardActions className={classes.cardActions}>{emailButton}</CardActions>
    );
  } else if (site.length > 0) {
    return (
      <CardActions className={classes.cardActions}>
        {personalSiteButton}
      </CardActions>
    );
  } else {
    return (
      <CardActions className={classes.cardActions}>{noLinksIcon}</CardActions>
    );
  }
};

export default ProfileCardButtons;
