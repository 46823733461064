import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import ProfileCard from './ProfileCard';
import { Profile } from '../../types';
import { screenWidthSettings } from '../../constants';
import FadeInSection from '../FadeInSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
		section: {
			width: '100%',
			maxWidth: screenWidthSettings.largeLaptopScreenSize,
			marginTop: '3rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		sectionTitle: {
			fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
			fontWeight: 600,
		},
		cards: {
			overflow: 'hidden', // prevents scroll bar from appearing when animating cards
			width: '80%',
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			[theme.breakpoints.down('xs')]: {
        width: '100%',
      },
		},
	}),
)

const PeopleSection = (
  { profileArr, sectionTitle }: 
  { profileArr: Profile[], sectionTitle: string }): 
  JSX.Element => {
  const classes = useStyles();
  
  if (profileArr.length > 0) {
    return (
      <section className={classes.section}>
				<FadeInSection>
					<Typography
						align='center'
						variant={'h4'}
						component='h2'
						className={classes.sectionTitle}
					>
						{sectionTitle}
					</Typography>
				</FadeInSection>
				<div className={classes.cards}>
					{profileArr.map((profile: Profile) =>
							<ProfileCard 
								profile={profile}
								key={profile.first + profile.last + profile.role}
							/>
					)}
				</div>
			</section>
    );
  } else {
    return (
      <div></div>
    );
  };
};

export default PeopleSection;