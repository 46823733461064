import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { screenWidthSettings } from '../../constants';
import { PeopleAppliedFilters, PeopleState, State } from '../../types';
import {
  processSearchQuery,
  filterPeople,
  sortPeople,
} from '../../features/peopleSlice';
import SearchBar from '../SearchBar';
import DropdownSelect from '../DropdownSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
    },
    accordion: {
      width: '100%',
    },
    accordionSummary: {
      backgroundColor: '#E6E6E6',
    },
    accordionContent: {
      backgroundColor: '#F5F5F5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    refineResults: {
      backgroundColor: 'white',
      padding: '2rem 0 0 0',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    refineResultsTitle: {
      width: '100%',
      maxWidth: screenWidthSettings.laptopScreenSize,
      padding: '0 1rem 0 1rem',
      display: 'flex',
      alignItems: 'center',
    },
    features: {
      padding: '0 1rem 0 1rem',
      width: '100%',
      maxWidth: screenWidthSettings.laptopScreenSize,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      [theme.breakpoints.down(425)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    filterAndSort: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    dropdown: {
      margin: '10px 20px 10px 0',
      [theme.breakpoints.down(769)]: {
        width: '100%',
        maxWidth: screenWidthSettings.searchBarWidth,
        marginRight: 0,
      },
    },
    searchBarContainer: {
      margin: '10px 0 10px 0',
      [theme.breakpoints.down(769)]: {
        width: '100%',
        maxWidth: screenWidthSettings.searchBarWidth,
      },
    },
    divider: {
      width: '96%',
      maxWidth: '990px',
      marginTop: '2rem',
      borderTop: '2px solid black',
    },
  })
);

const PeopleRefineResults = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize.toString()}px)`
  );
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize.toString()}px)`
  );
  const peopleState = useSelector(
    (state: State): PeopleState => state.peopleReducer
  );
  const currentFilters: PeopleAppliedFilters = peopleState.appliedFilters;
  const currentSort: string = peopleState.appliedSort;
  const currentSearchQuery: string = peopleState.searchQuery;

  //if screen size is tablet or smaller, return contents inside an accordion
  if (isTabletScreen) {
    return (
      <Accordion className={classes.accordion} id='people-refine-results'>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='refine-results-filters'
          id='people-refine-results-header'
        >
          <Typography
            align='left'
            variant={isMobileScreen ? 'h6' : 'h5'}
            className={classes.title}
            component='h2'
          >
            Refine Results
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
          {Object.entries(currentFilters).map(([key, value]) => (
            <div className={classes.dropdown} key={key}>
              <DropdownSelect
                actionCreator={filterPeople}
                dropdownName={key}
                label='Member Status'
                options={['All', 'Current', 'Former']}
                selectedOption={value}
                width='100%'
              />
            </div>
          ))}
          <div className={classes.dropdown}>
            <DropdownSelect
              actionCreator={sortPeople}
              dropdownName=''
              label='Sort By'
              options={[
                'None',
                'First Name A-Z',
                'First Name Z-A',
                'Last Name A-Z',
                'Last Name Z-A',
              ]}
              selectedOption={currentSort}
              width='100%'
            />
          </div>
          <div className={classes.searchBarContainer}>
            <SearchBar
              placeholder='Search people'
              actionCreator={processSearchQuery}
              renderLabel={true}
              searchQuery={currentSearchQuery}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={classes.refineResults} id='people-refine-results'>
      <div className={classes.refineResultsTitle}>
        <Typography
          align='left'
          variant={isMobileScreen ? 'h6' : 'h5'}
          className={classes.title}
          component='h2'
        >
          Refine Results
        </Typography>
      </div>
      <div className={classes.features}>
        <div className={classes.filterAndSort}>
          {Object.entries(currentFilters).map(([key, value]) => (
            <div className={classes.dropdown} key={key}>
              <DropdownSelect
                actionCreator={filterPeople}
                dropdownName={key}
                label='Member Status'
                options={['All', 'Current', 'Former']}
                selectedOption={value}
                width='100%'
              />
            </div>
          ))}
          <div className={classes.dropdown}>
            <DropdownSelect
              actionCreator={sortPeople}
              dropdownName=''
              label='Sort By'
              options={[
                'None',
                'First Name A-Z',
                'First Name Z-A',
                'Last Name A-Z',
                'Last Name Z-A',
              ]}
              selectedOption={currentSort}
              width='100%'
            />
          </div>
        </div>
        <div className={classes.searchBarContainer}>
          <SearchBar
            placeholder='Search people'
            actionCreator={processSearchQuery}
            renderLabel={true}
            searchQuery={currentSearchQuery}
          />
        </div>
      </div>
      <hr className={classes.divider} />
    </div>
  );
};

export default PeopleRefineResults;
